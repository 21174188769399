import React, { useRef, useEffect } from 'react';
import '../App.css';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const LocalVideo = props => {
  const localVideoRef = useRef();

  useEffect(() => {
    if (props.stream && localVideoRef.current) {
      localVideoRef.current.srcObject = props.stream;
    }
  }, [props.stream]);

  return (
    <div style={{position: 'relative'}}>
      <video ref={localVideoRef} className='videoLocal' autoPlay playsInline muted
        style={{
          backgroundColor: 'darkgray',
        }}
      />

      <Box sx={{position: 'absolute', left: '8px', top: '8px', zIndex: 1}}>
        <IconButton onClick={props.handleToggleMute}>
          {props.isMuted
            ? <VolumeUpIcon />
            : <VolumeOffIcon />
          }
        </IconButton>
      </Box>
    </div>
  );
};

export default LocalVideo;