import { useEffect, useState } from "react";

export default function useScreenDetector() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 600; // 768
  const isTablet = width > 600 && width <= 1024; // 1024
  const isDesktop = width > 1024; // 1024

  return { isMobile, isTablet, isDesktop };
};