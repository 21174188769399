import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
// import io from 'socket.io-client';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

import Chat from './Components/Chat';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  /**
   * Constants
   */

  /**
   * States
   */
  const [gender, setGender] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [user, setUser] = useState(null);

  /**
   * Hooks
   */

  /**
   * Effects
   */
  // useEffect(() => {
  //   localStorage.removeItem('userSession');
  // }, []);
  useEffect(() => {
    const checkUserSession = async () => {
      const userSession = localStorage.getItem('userSession');

      if (userSession) {
        setUser(jwtDecode(userSession));
      }
    };

    checkUserSession();

    // window.document.body.requestFullscreen() // ====> document.exitFullscreen()
    //   .then(() => {
    //     window.screen.orientation.lock('portrait')
    //       .then(() => {
    //         alert(`Locked to 'portrait'`);
    //       })
    //       .catch((error) => {
    //         alert(`window.screen.orientation.lock ${error}`);
    //       });
    //   })
    //   .catch((error) => {
    //     alert(`window.document.body.requestFullscreen() ${error}`);
    //   });
  }, []);

  /**
   * Custom handlers
   */
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    console.log(credentialResponse);

    const userSession = jwtDecode(credentialResponse.credential);
    localStorage.setItem('userSession', credentialResponse.credential);
    setUser(userSession);
    /*
      aud: "Audience" - Publicul pentru care token-ul JWT este destinat. În acest caz, este tot ID-ul clientului autorizat (azp).
      azp: "Authorized Party" - ID-ul clientului autorizat care a generat token-ul. Acesta este ID-ul unic al aplicației (clientului) care a solicitat autentificarea utilizatorului.
      email_verified: Indică dacă adresa de email a utilizatorului a fost verificată și este validată de Google.
      email: Adresa de email a utilizatorului autentificat.
      exp: "Expiration Time" - Timestamp-ul la care token-ul expiră și nu mai este valabil.
      family_name: Numele de familie al utilizatorului.
      given_name: Prenumele utilizatorului.
      iat: "Issued At" - Timestamp-ul la care token-ul a fost emis (începutul valabilității).
      iss: "Issuer" - Adresă URL a serverului de autentificare care a emis token-ul. În acest caz, este URL-ul Google Accounts (https://accounts.google.com).
      jti: "JWT ID" - ID-ul unic al token-ului JWT.
      name: Numele complet al utilizatorului autentificat.
      nbf: "Not Before" - Timestamp-ul în care token-ul nu trebuie să fie folosit înainte de această dată.
      picture: URL-ul către imaginea de profil a utilizatorului.
      sub: "Subject" - ID-ul unic al utilizatorului autentificat. Acesta este un identificator unic al utilizatorului în cadrul domeniului emițătorului (Google în acest caz).
     */
  };

  const handleGoogleLogout = () => {
    googleLogout();
    localStorage.removeItem('userSession');
    setUser(null);
  };

  /**
   * Renderer
   */
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      {user === null
        ? <Box sx={{position: 'fixed', top: '0px', left: '0px', right: '0px', bottom: '0px', mb: '16px', p: '4px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 2, overflow: 'auto'}}>
            {/* <Typography sx={{maxWidth: '640px', fontSize: '2em', textAlign: 'center', lineHeight: '1em'}}>Strngle.com is here because others are looking for you!</Typography> */}
            <img src='/images/logo2.png' />
            {/* <Typography sx={{maxWidth: '640px', fontSize: '2em', textAlign: 'center', lineHeight: '1em'}}>Why waste time?</Typography>
            <Typography sx={{maxWidth: '640px', fontSize: '2em', textAlign: 'center', lineHeight: '1em'}}>Get the best by engaging in conversations with people you haven't met yet!</Typography> */}

            <Box sx={{width: '320px', maxWidth: '320px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <TextField size='small' fullWidth select
                label='I am a ...'
                helperText='Please select your gender*'
                error={gender === ''}
                value={gender}
                onChange={evt => setGender(evt.target.value)}
              >
                <MenuItem value='m'>Male</MenuItem>
                <MenuItem value='f'>Female</MenuItem>
                <MenuItem value='c'>Couple</MenuItem>
                <MenuItem value='t'>Transgender</MenuItem>
                <MenuItem value='?'>Something else</MenuItem>
              </TextField>

              <FormControlLabel
                label={<Typography color={theme => agreement ? theme.palette.action.main : theme.palette.error.main}>I am over 18yo and I agree the T&C</Typography>}
                control={
                  <Checkbox
                    checked={agreement}
                    onChange={evt => setAgreement(evt.target.checked)}
                  />
                }
              />

              <Box sx={{height: '40px'}}>
                {gender !== '' && agreement &&
                  <GoogleLogin // auto_select
                    theme='filled_blue'
                    size='large'
                    shape='rectangular'
                    width='320'
                    onSuccess={handleGoogleLoginSuccess}
                    onError={() => {
                      console.log('Login Failed');
                      localStorage.removeItem('userSession');
                      setUser(null);
                    }}
                  />
                }
              </Box>
            </Box>

            <Box sx={{maxWidth: '640px', textAlign: 'center'}}>
              <Typography sx={{display: 'inline', fontSize: '1.5em', lineHeight: '1em'}}>What is </Typography>
              <Typography sx={{display: 'inline', fontSize: '1.2rem', fontWeight: 'bold', letterSpacing: '0px', color: theme => theme.palette.primary.main}}>FREAKEL</Typography>
              <Typography sx={{display: 'inline', fontSize: '1.5em', lineHeight: '1em'}}>?</Typography>
            </Box>
            <Box sx={{maxWidth: '640px', textAlign: 'center'}}>
              <Typography sx={{display: 'block', fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1em', color: theme => theme.palette.secondary.main}}>Creativity and originality.</Typography>
              <Typography sx={{display: 'inline', fontSize: '1.2rem', fontWeight: 'bold', lineHeight: '1em', letterSpacing: '0px', color: theme => theme.palette.primary.main}}>FREAKEL</Typography>
              <Typography sx={{display: 'inline', fontSize: '1.5em', lineHeight: '1em'}}> is unconventional, distinctive, and nonconformist. It represents an innovative idea and a unique concept.</Typography>
            </Box>
            <Box sx={{maxWidth: '640px', textAlign: 'center'}}>
              <Typography sx={{display: 'block', fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1em', color: theme => theme.palette.secondary.main}}>Diversity and differences.</Typography>
              <Typography sx={{display: 'inline', fontSize: '1.2rem', fontWeight: 'bold', lineHeight: '1em', letterSpacing: '0px', color: theme => theme.palette.primary.main}}>FREAKEL</Typography>
              <Typography sx={{display: 'inline', fontSize: '1.5em', lineHeight: '1em'}}> is associated with the idea of diversity and individuality. It's a place where different individuals and ideas are appreciated and celebrated.</Typography>
            </Box>
            <Box sx={{maxWidth: '640px', textAlign: 'center'}}>
            <Typography sx={{display: 'block', fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1em', color: theme => theme.palette.secondary.main}}>Alternative lifestyle.</Typography>
              <Typography sx={{display: 'inline', fontSize: '1.2rem', fontWeight: 'bold', lineHeight: '1em', letterSpacing: '0px', color: theme => theme.palette.primary.main}}>FREAKEL</Typography>
              <Typography sx={{display: 'inline', fontSize: '1.5em', lineHeight: '1em'}}> indicates an interest in alternative lifestyles and subcultures, such as alternative fashion, underground music, or other non-mainstream interests.</Typography>
            </Box>
            <Box sx={{maxWidth: '640px', textAlign: 'center'}}>
             <Typography sx={{display: 'block', fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1em', color: theme => theme.palette.secondary.main}}>Entertainment and online community.</Typography>
             <Typography sx={{display: 'inline', fontSize: '1.2rem', fontWeight: 'bold', lineHeight: '1em', letterSpacing: '0px', color: theme => theme.palette.primary.main}}>FREAKEL</Typography>
             <Typography sx={{display: 'inline', fontSize: '1.5em', lineHeight: '1em'}}> is an online entertainment platform and community that aims to provide innovative and captivating content for an audience interested in diverse or unique topics.</Typography>
            </Box>
          </Box>
        : <Chat user={user} gender={gender} onGoogleLogout={handleGoogleLogout} />
      }
    </ThemeProvider>
  );
}

export default App;
