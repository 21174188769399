import React, { useRef, useEffect } from 'react';
import '../App.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const RemoteVideo = props => {
  const remoteVideoRef = useRef();

  useEffect(() => {
    if (props.stream && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = props.stream;
    }
  }, [props.stream]);

  return (
    <div style={{position: 'relative'}}>
      <video ref={remoteVideoRef} className='videoRemote' autoPlay playsInline
        style={{
          // width: 'min(calc(((100vh - 54px) / 2) / 3 * 4), calc(100vw - 360px))',
          // maxWidth: '100vw',
          // height: 'min(calc((100vh - 54px) / 2), calc((100vw - 360px) / 4 * 3))',
          // maxHeight: 'calc((100vh - 54px) / 2)',
          backgroundColor: 'black',
          // objectFit: 'cover',
        }}
      />

      {/* {!props.chat.isConnecting && !props.chat.isConnected &&
        <div
          style={{
            position: 'absolute',
            top: '50%',
            width: '100%',
            fontSize: '40px',
            fontWeight: 'bold',
            color: 'forestgreen',
            textAlign: 'center',
            transform: 'translateY(-50%)',
            // textShadow: 'white 2px 2px 3px',
          }}
        >Press <span style={{color: 'limegreen'}}>ESCAPE</span> to connect with a stranger</div>
      } */}

      {props.chat.isConnected &&
        <div
          style={{
            position: 'absolute',
            left: '8px',
            bottom: '8px',
            fontFamily: 'cursive',
            fontSize: '36px',
            fontWeight: 'bold',
            color: 'hotpink',
            textShadow: 'black 0px 0px 4px, white 0px 0px 8px',
            userSelect: 'none',
          }}
        >Strngle<span style={{fontSize: '0.5em'}}>.com</span></div>
      }

      {props.isMobile &&
        <Box sx={{position: 'absolute', right: '8px', bottom: '-48px', zIndex: 1}}>
          {props.chat.isConnected
            ? <Button variant='contained' color='warning' fullWidth sx={{flexDirection: 'column', height: '100%'}}
                disabled={!props.webSocket}
                onClick={props.handleDisconnect()}
              >
                <div style={{fontSize: '120%', fontWeight: 'bold'}}>Stop</div>
                {!props.isMobile &&
                  <div style={{fontSize: '80%'}}>(ESC)</div>
                }
              </Button>
            : props.chat.isConnecting
              ? <Button variant='contained' color='inherit' fullWidth sx={{flexDirection: 'column', height: '100%'}}
                  disabled={!props.webSocket}
                  onClick={props.handleCancelConnect()}
                >
                  <div style={{fontSize: '120%', fontWeight: 'bold'}}>Cancel</div>
                  {!props.isMobile &&
                    <div style={{fontSize: '80%'}}>(ESC)</div>
                  }
                </Button>
              : <Button variant='contained' color='success' fullWidth sx={{flexDirection: 'column', height: '100%'}}
                  disabled={!props.webSocket}
                  onClick={props.handleConnect()}
                >
                  <div style={{fontSize: '120%', fontWeight: 'bold'}}>Start</div>
                  {!props.isMobile &&
                    <div style={{fontSize: '80%'}}>(ESC)</div>
                  }
                </Button>
          }
        </Box>
      }
    </div>
  );
};

export default RemoteVideo;