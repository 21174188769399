import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
// import io from 'socket.io-client';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import useScreenDetector from '../Hooks/useScreenDetector';
// import useWindowDimensions from '../Hooks/useWindowDimensions';
import RemoteVideo from './RemoteVideo';
import LocalVideo from './LocalVideo';

const defaultChat = {
  permission: null, // null: prompt, false: denied, true: granted
  idSession: null,
  idUser: null,
  idConnection: null,
  idChat: null,
  guid: null,
  mode: 'video',
  useInterests: false,
  interests: [],
  isConnecting: false,
  isConnected: false,
  strangerIsTyping: false,
};

var webSocket;
var myPeerConnection;

function Chat(props) {
  /**
   * States
   */
  const [chat, setChat] = useState(defaultChat);
  // const [videoDevices, setVideoDevices] = useState([]);
  // const [audioDevices, setAudioDevices] = useState([]);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [selection, setSelection] = useState({
    anchorEl: null,
    op: null,
  });
  const [isMuted, setIsMuted] = useState(false);

  /**
   * Hooks
   */
  const refs = {
    messages: useRef(null),
    interestsField: useRef(null),
    messageField: useRef(null),
  };
  // const { wHeight, wWidth } = useWindowDimensions();
  const { isMobile } = useScreenDetector();

  /**
   * Constants
   */
  // const getBestVideoDevice = () => videoDevices[0];
  // const getBestAudioDevice = () => audioDevices[0];

  /**
   * Effects
   */
  useEffect(() => {
    handleAskMediaPermissions();
    handleWebSocketInit();

    const handleEscapePress = evt => {
      if (evt.key === 'Escape') {
        if (chat.isConnected) {
          handleDisconnect()();
        } else if (chat.isConnecting) {
          handleCancelConnect()();
        } else {
          handleConnect()();
        }
      }
    }
    document.addEventListener('keydown', handleEscapePress);


    return () => {
      webSocket.close();
      document.removeEventListener('keydown', handleEscapePress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chat.permission) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          // setVideoDevices(() => devices.filter((device) => device.kind === 'videoinput'));
          // setAudioDevices(() => devices.filter((device) => device.kind === 'audioinput'));

          navigator.mediaDevices.getUserMedia({audio: true, video: true}).then(mediaStream => {
            setLocalStream(mediaStream);
          }).catch((err) => {
            console.error(`${err.name}: ${err.message}`);
          });
        }).catch(err => {
          console.error(`${err.name}: ${err.message}`);
        });
    }
  }, [chat.permission]);

  useEffect(() => {
    if (!refs.messages.current) {
      return;
    }

    refs.messages.current.scrollTop = refs.messages.current.scrollHeight;
  }, [chatMessages, refs.messages]);

  useEffect(() => {
    if (chat.useInterests) {
      refs.interestsField.current.focus();
    }
  }, [chat.useInterests, refs.interestsField]);

  useEffect(() => {
    if (chat.isConnected) {
      if (!isMobile) {
        refs.messageField.current.focus();
      }

      sendToServer({
        op: 'strangerIsTyping',
        data: message !== '',
      });
    }
  }, [chat.isConnected, message, isMobile, refs.messageField]);

  useEffect(() => {
    if (!refs.messageField.current) return;

    if (isMobile) {
      refs.messageField.current.blur();
    } else {
      refs.messageField.current.focus();
    }
  }, [isMobile, refs.messageField]);

  /**
   * Media devices handlers
   */
  const handleAskMediaPermissions = () => {
    navigator.mediaDevices.getUserMedia({audio: true, video: true})
      .then(stream => {
        setChat(prevState => ({
          ...prevState,
          permission: true,
        }));
        stream.getTracks().forEach(x=>x.stop());
      }).catch(err => {
        setChat(prevState => ({
          ...prevState,
          permission: false,
        }));
        console.error(`${err.name}: ${err.message}`);
      });
  };

  /**
   * WebSocket handlers
   */
  const handleWebSocketInit = () => {
    webSocket = new WebSocket('wss://ws.chat.isapi.ro/');
    webSocket.onopen = handleWebSocketOpenEvent;
    webSocket.onmessage = handleWebSocketMessageEvent;
    webSocket.onerror = handleWebSocketErrorEvent;
    webSocket.onclose = handleWebSocketCloseEvent;
  };
  const handleWebSocketOpenEvent = event => {
    sendToServer({
      op: 'hello',
      data: {
        ...props.user,
        gender: props.gender,
      },
    });
  };
  const handleWebSocketMessageEvent = event => {
    const response = JSON.parse(event.data);

    if (response.op === 'welcome') {
      setChat(prevState => ({
        ...prevState,
        guid: response.guid,
      }));
    }
    if (response.op === 'queued') { // The server ask me to wait until a partner is found
    }
    if (response.op === 'connect') { // The server ask me to initiate the connection
      setChat(prevState => ({
        ...prevState,
        idChat: response.idChat,
      }))
      setTimeout(() => {
        invite();
      }, 100);
    }
    if (response.op === 'wait') { // The server tell me that a partner was found and he will initiate the connection
      setChat(prevState => ({
        ...prevState,
        idChat: response.idChat,
      }))
    }
    if (response.op === 'video-offer') { // The other user initiated the connection
      handleVideoOfferMsg(response.data);
    }
    if (response.op === 'video-answer') { // The other user answered to our video offer
      handleVideoAnswerMsg(response.data);
    }
    if (response.op === 'new-ice-candidate') { // The other user sent me a new ICE candidate
      handleNewICECandidateMsg(response.data);
    }
    if (response.op === 'strangerIsTyping') { // The other user start typing or no
      setChat(prevState => ({
        ...prevState,
        strangerIsTyping: response.data,
      }));
    }
    if (response.op === 'message') { // The other user sent me a new message
      handleAddChatMessage({who: 'Stranger', message: response.data, severity: 'info'});
    }
    if (response.op === 'hang-up') { // The other user disconnected
      closeVideoCall();
      handleAddChatMessage({who: 'SERVER', message: 'Stranger has disconnected', severity: 'info'});
    }
    if (Boolean(response.status?.message)) {
      handleAddChatMessage({who: 'SERVER', message: response.status.message, severity: response.status.severity});
    }
  };
  const handleWebSocketErrorEvent = event => {
    console.warn('Connection error!', event);
  };
  const handleWebSocketCloseEvent = event => {
    console.warn('Connection closed!', event);
    closeVideoCall();
    handleAddChatMessage({who: 'SERVER', message: 'The server closed the connection. Something went wrong. Please refresh the app.', severity: 'error'});
    webSocket = null;
    // handleWebSocketInit();
  };

  /**
   * WebRTC handlers
   */
  const sendToServer = msg => webSocket.send(JSON.stringify(msg));
  const handleICECandidateEvent = event => {
    if (event.candidate) {
      sendToServer({
        op: 'new-ice-candidate',
        data: event.candidate,
      });
    }
  };
  const handleTrackEvent = event => {
    setRemoteStream(event.streams[0]);
    setChat(prevState => ({
      ...prevState,
      isConnecting: false,
      isConnected: true,
    }));
    // handleAddChatMessage({who: 'SERVER', message: 'You are now connected with a random stranger. Have fun together!', severity: 'info'}, true);
  };
  const handleNegotiationNeededEvent = () => {
    myPeerConnection
      .createOffer()
      .then(offer => myPeerConnection.setLocalDescription(offer))
      .then(() => {
        sendToServer({
          op: 'video-offer',
          data: myPeerConnection.localDescription,
        });
      })
      .catch(reportError);
  };
  const handleRemoveTrackEvent = event => {
    if (remoteStream.getTracks().length === 0) {
      closeVideoCall();
    }
  };
  const handleICEConnectionStateChangeEvent = event => {
    switch (myPeerConnection.iceConnectionState) {
      case 'closed':
      case 'failed':
        closeVideoCall();
        break;
      default:
    }
  };
  const handleICEGatheringStateChangeEvent = event => {
    // Our sample just logs information to console here,
    // but you can do whatever you need.
    // console.log(`handleICEGatheringStateChangeEvent() with event:`, event);
  };
  const handleSignalingStateChangeEvent = event => {
    switch (myPeerConnection.signalingState) {
      case 'closed':
        closeVideoCall();
        break;
      default:
    }
  };
  const handleNewICECandidateMsg = candidate => {
    const iceCandidate = new RTCIceCandidate(candidate);

    try {
      myPeerConnection.addIceCandidate(iceCandidate);
    } catch (error) {}
    // myPeerConnection.addIceCandidate(iceCandidate).catch(reportError);
  }

  /**
   * Chat handlers
   */
  const invite = () => {
    if (myPeerConnection) {
      alert('You can\'t start a call because you already have one open!');
    } else {
      createPeerConnection()
        .then(() => {
          navigator.mediaDevices
            .getUserMedia({
              video: true,
              audio: true,
            })
            .then(stream => {
              stream
                .getTracks()
                .forEach(track => myPeerConnection.addTrack(track, stream));
            })
            .catch(handleGetUserMediaError);
        });
    }
  };
  const handleVideoOfferMsg = data => {
    createPeerConnection()
      .then(() => {
        const desc = new RTCSessionDescription(data);

        myPeerConnection
          .setRemoteDescription(desc)
          .then(() => {
            return navigator.mediaDevices.getUserMedia({
              video: true,
              audio: true,
            });
          })
          .then(stream => {
            stream
              .getTracks()
              .forEach(track => myPeerConnection.addTrack(track, stream));
          })
          .then(() => myPeerConnection.createAnswer())
          .then(answer => myPeerConnection.setLocalDescription(answer))
          .then(() => {
            sendToServer({
              op: 'video-answer',
              data: myPeerConnection.localDescription,
            });
          })
          .catch(handleGetUserMediaError);
      });
  };
  const handleVideoAnswerMsg = data => {
    const desc = new RTCSessionDescription(data);

    myPeerConnection
      .setRemoteDescription(desc);
  };
  const createPeerConnection = async () => {
    return fetch('https://isapi.metered.live/api/v1/turn/credentials?apiKey=4e9be5f8f669d3b8e534bdfacc0db717addc')
      .then(response => response.json())
      .then(iceServers => {
        myPeerConnection = new RTCPeerConnection({
          iceServers: iceServers
        });

        myPeerConnection.onicecandidate = handleICECandidateEvent;
        myPeerConnection.ontrack = handleTrackEvent;
        myPeerConnection.onnegotiationneeded = handleNegotiationNeededEvent;
        myPeerConnection.onremovetrack = handleRemoveTrackEvent;
        myPeerConnection.oniceconnectionstatechange = handleICEConnectionStateChangeEvent;
        myPeerConnection.onicegatheringstatechange = handleICEGatheringStateChangeEvent;
        myPeerConnection.onsignalingstatechange = handleSignalingStateChangeEvent;
      });
  };
  const handleGetUserMediaError = e => {
    switch (e.name) {
      case 'NotFoundError':
        alert(
          'Unable to open your call because no camera and/or microphone were found.',
        );
        break;
      case 'SecurityError':
      case 'PermissionDeniedError':
        // Do nothing; this is the same as the user canceling the call.
        break;
      default:
        alert(`Error opening your camera and/or microphone: ${e.message}`);
        break;
    }

    closeVideoCall();
  };
  const hangUpCall = () => {
    closeVideoCall();
    sendToServer({
      op: 'hang-up',
      data: null,
    });
    setMessage('');
    handleAddChatMessage({who: 'SERVER', message: 'You have disconnected', severity: 'info'});
  };
  const closeVideoCall = () => {
    if (myPeerConnection) {
      myPeerConnection.ontrack = null;
      myPeerConnection.onremovetrack = null;
      myPeerConnection.onremovestream = null;
      myPeerConnection.onicecandidate = null;
      myPeerConnection.oniceconnectionstatechange = null;
      myPeerConnection.onsignalingstatechange = null;
      myPeerConnection.onicegatheringstatechange = null;
      myPeerConnection.onnegotiationneeded = null;

      setRemoteStream(null);

      myPeerConnection.close();
      myPeerConnection = null;
    }

    setChat(prevState => ({
      ...prevState,
      isConnecting: false,
      isConnected: false,
    }));
  };

  /**
   * Custom handlers
   */
  const handleConnect = () => () => {
    // handleAddChatMessage({who: 'SERVER', message: 'Looking for a stranger...', severity: 'info'});
    setChat(prevState => ({
      ...prevState,
      isConnecting: true,
      isConnected: false,
    }));
    setChatMessages(prevState => []);
    sendToServer({
      op: 'connect',
      data: {
        mode: chat.mode,
      },
      guid: chat.guid,
      mode: chat.mode,
      interests: [],
    });
  };
  const handleDisconnect = () => () => {
    hangUpCall();
  };
  const handleCancelConnect = () => () => {
    sendToServer({
      op: 'cancelConnect',
    });
    setChat(prevState => ({
      ...prevState,
      isConnecting: false,
    }));
  };

  const handleAddChatMessage = (chatMessage, clearChatBefore = false) => {
    if (clearChatBefore) {
      setChatMessages(prevState => [chatMessage]);
    } else {
      setChatMessages(prevState => [...prevState, chatMessage]);
    }
  };
  const handleSendMessage = () => {
    if (message === '') {
      return;
    }

    sendToServer({
      op: 'message',
      data: message,
    });
    handleAddChatMessage({who: 'You', message: message, severity: 'info'});
    setMessage('');
  };

  const handleToggleMute = () => {
    const audioTracks = localStream.getAudioTracks();
    if (audioTracks.length > 0) {
      audioTracks.forEach(track => {
        track.enabled = !track.enabled;
      });
    }

    setIsMuted(prevState => !prevState);
  };

  /**
   * Renderer
   */
  return (
    <Box sx={{position: 'fixed', top: '0px', left: '0px', right: '0px', bottom: '0px'}}>
      <Box className='header'>
        <img src='/images/logo.png' alt='logo' style={{width: '40px', height: '40px', mr: '4px', scale: '1.7'}} />
        <Typography sx={{fontSize: '48px', fontWeight: 'bold', letterSpacing: '-4px', color: theme => theme.palette.secondary.main}}>Strngle</Typography>
      </Box>

      <Box className='menuButton'>
        <IconButton onClick={evt => setSelection({anchorEl: evt.currentTarget})}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={selection.anchorEl}
          open={Boolean(selection.anchorEl)}
          onClose={() => setSelection({anchorEl: null})}
        >
          {/* <MenuItem onClick={() => setSelection({anchorEl: null, op: 'profile'})}>Profile</MenuItem> */}
          {/* <MenuItem onClick={() => setSelection({anchorEl: null, op: 'myAccount'})}>My account</MenuItem> */}
          <MenuItem onClick={props.onGoogleLogout}>Logout</MenuItem>
        </Menu>
      </Box>

      <Box className='videoContainer'>
        <RemoteVideo stream={remoteStream} chat={chat} isMobile={isMobile} webSocket={webSocket} handleConnect={handleConnect} handleDisconnect={handleDisconnect} handleCancelConnect={handleCancelConnect} />
        <LocalVideo stream={localStream} isMuted={isMuted} handleToggleMute={handleToggleMute} />
      </Box>

      <Box className='chat'>
        {/* <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}> */}
          <Box ref={refs.messages} className='chatMessages'>
            {chat.guid && !chat.isConnecting && !chat.isConnected &&
              <Box sx={{display: 'inline-flex'}}>
                <Typography fontWeight='bold'><span>You are now online! Hit START to connect with a random stranger.</span></Typography>
              </Box>
            }
            {chat.guid && chat.isConnecting && !chat.isConnected &&
              <Box sx={{display: 'inline-flex'}}>
                <Typography fontWeight='bold'><span>Looking for a stranger to connect with...</span></Typography>
              </Box>
            }
            {chat.guid && !chat.isConnecting && chat.isConnected &&
              <Box sx={{display: 'inline-flex'}}>
                <Typography fontWeight='bold'><span>You are now connected with a random stranger. Have fun together!</span></Typography>
              </Box>
            }
            {chatMessages.map((chatMessage, index) => (
              <Box key={index} className={chatMessage.who === 'You' ? 'chatMessageRight' : 'chatMessageLeft'} sx={{display: 'inline-flex'}}>
                {chatMessage.who === 'SERVER'
                  ? <Typography component='div' color={chatMessage.severity}><span style={{fontWeight: 'bold'}}>{chatMessage.message}</span></Typography>
                  : <Typography component='div' color={chatMessage.severity} sx={{display: 'flex'}}>
                      <strong className='chatMessageFrom' style={{marginRight: '4px', color: chatMessage.who === 'You' ? 'blue' : 'red'}}>{chatMessage.who}</strong>
                      <Tooltip disableInteractive followCursor title='Click to copy to clipboard'>
                        <Box className='chatMessage' sx={{backgroundColor: theme => `color-mix(in srgb, ${chatMessage.who === 'SERVER' ? theme.palette.warning.main : chatMessage.who === 'You' ? theme.palette.success.main : theme.palette.info.main} 60%, transparent)`}}
                          onClick={() => navigator.clipboard.writeText(chatMessage.message)}
                        >{chatMessage.message}</Box>
                      </Tooltip>
                    </Typography>
                }
              </Box>
            ))}
            {chat.strangerIsTyping &&
              <Box sx={{display: 'inline-flex'}}>
                <Typography><span>Stranger is typing...</span></Typography>
              </Box>
            }

            {chat.guid && !chat.isConnecting && !chat.isConnected &&
              <Box>
                <FormControlLabel
                  label='Use common interests to find strangers'
                  control={
                    <Checkbox size='small'
                      checked={chat.useInterests}
                      onChange={evt => setChat(prevState => ({
                        ...prevState,
                        useInterests: evt.target.checked,
                        interests: [],
                      }))}
                    />
                  }
                />
                <Autocomplete multiple freeSolo fullWidth
                  disabled={!chat.useInterests}
                  options={chat.interests}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} inputRef={refs.interestsField} variant='outlined' size='small'
                      placeholder='Type your interest and hit ENTER to add it to the list'
                      label='Type your interest and hit ENTER to add it to the list'
                    />
                  )}
                  onChange={(event, value, reason, details) => {
                    switch (reason) {
                      case 'createOption':
                      case 'removeOption':
                        setChat(prevState => ({
                          ...prevState,
                          interests: value.filter((value, index, array) => array.indexOf(value) === index),
                        }));
                        break;
                      default:
                        console.log(`Unhandled autocomplete event:`, value, reason, details);
                    }
                  }}
                />
              </Box>
            }

            {/* <Box>{`${wWidth} x ${wHeight}`}</Box> */}
            {/* <Box>{`isMobile: ${isMobile}`}</Box> */}
          </Box>

          <Box className='chatNewMessage' sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
            {!isMobile &&
              <Box sx={{flex: '120px 0 0'}}>
                {chat.isConnected
                  ? <Button variant='contained' color='warning' fullWidth sx={{flexDirection: 'column', height: '100%'}}
                      disabled={!webSocket}
                      onClick={handleDisconnect()}
                    >
                      <div style={{fontSize: '120%', fontWeight: 'bold'}}>Stop</div>
                      {!isMobile &&
                        <div style={{fontSize: '80%'}}>(ESC)</div>
                      }
                    </Button>
                  : chat.isConnecting
                    ? <Button variant='contained' color='inherit' fullWidth sx={{flexDirection: 'column', height: '100%'}}
                        disabled={!webSocket}
                        onClick={handleCancelConnect()}
                      >
                        <div style={{fontSize: '120%', fontWeight: 'bold'}}>Cancel</div>
                        {!isMobile &&
                          <div style={{fontSize: '80%'}}>(ESC)</div>
                        }
                      </Button>
                    : <Button variant='contained' color='success' fullWidth sx={{flexDirection: 'column', height: '100%'}}
                        disabled={!webSocket}
                        onClick={handleConnect()}
                      >
                        <div style={{fontSize: '120%', fontWeight: 'bold'}}>Start</div>
                        {!isMobile &&
                          <div style={{fontSize: '80%'}}>(ESC)</div>
                        }
                      </Button>
                }
              </Box>
            }
            <TextField variant='outlined' fullWidth multiline={!isMobile} maxRows={isMobile ? 1 : 3} /*autoFocus={!isMobile}*/
              inputProps={{id: '', autoComplete: 'new-password', inputMode: 'text'}}
              inputRef={refs.messageField}
              disabled={!chat.isConnected}
              placeholder='Type your message here...'
              value={message}
              onChange={evt => setMessage(evt.target.value)}
              onKeyDown={evt => {
                if (evt.key === 'Enter' && !evt.shiftKey) {
                  evt.preventDefault();
                  handleSendMessage();
                }
              }}
              onBlur={() => isMobile ? refs.messageField.current.blur() : refs.messageField.current.focus()}
            />
            <Button variant='contained' color='primary' sx={{flexDirection: 'column', flex: '80px 0 0'}}
              disabled={!chat.isConnected || message === ''}
              onClick={() => handleSendMessage()}
            >
              <div style={{fontSize: '120%', fontWeight: 'bold'}}>Send</div>
              {!isMobile && message !== '' &&
                <div style={{fontSize: '80%'}}>(ENTER)</div>
              }
            </Button>
          </Box>
        {/* </Box> */}
      </Box>

      {chat.permission === false &&
        <Dialog maxWidth='sm'
          open={chat.permission === false}
          // onClose={handleClose}
        >
          <DialogTitle>Use camera and microphone?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This is a video chat application. So, in order to use it, you'll have to let this application to use your camera and microphone. Your camera and microphone will be available only to the persons who you'll chat with and only for the period you chat with them.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Button variant='contained' size='small' onClick={handleAskMediaPermissions} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  );
}

export default Chat;
